import Vue from 'vue';
export const initMain = (mixins, components, plugins) => {
    if (plugins) {
        plugins.forEach(plugin => {
            Vue.use(plugin);
        });
    }
    const mainSelector = '#vue-container';
    return new Vue({
        el: mainSelector,
        mixins: mixins,
        components: components
    });
};