export const echartformat = (data, style, decimals) => {
    data = parseFloat(data);
    var opts = {
        style: style,
        maximumFractionDigits: decimals
    }
    if (style === 'currency') {
        opts.currency = 'COP';
    }
    return data.toLocaleString('es-CO', opts);
}

export const changeTransform = (element, value) => {
    if (element) {
        if (value || value === 0) {
            element.style["-webkit-transform"] = "translate(0px, " + value + "px)";
            element.style["-moz-transform"] = "translate(0px, " + value + "px)";
            element.style["-ms-transform"] = "translate(0px, " + value + "px)";
            element.style["-o-transform"] = "translate(0px, " + value + "px)";
            element.style["transform"] = "translate(0px, " + value + "px)";
        } else {
            element.style["-webkit-transform"] = null;
            element.style["-moz-transform"] = null;
            element.style["-ms-transform"] = null;
            element.style["-o-transform"] = null;
            element.style["transform"] = null;
        }
    }
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        const context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const toggleClass = (el, cl) => {
    if (el) {
        if (el.classList.contains(cl)) {
            el.classList.remove(cl);
        } else {
            el.classList.add(cl);
        }
    }
}