<template>
  <h2 class="tf" ref="previewText">
    <slot></slot>
  </h2>
</template>

<script>
import { DESKTOP_QUERY } from "../../../constants/constants";
import { debounce } from "../../../functions/general-functions";
export const tfmixin = {
  created: function () {
    this.tfNews = {};
  },
  data: function () {
    return {
      tfHeights: {},
    };
  },
  methods: {
    tfUpd: function (x, y, z) {
      const a = this.tfNews;
      const b = this.tfHeights;
      if (!a[x]) {
        a[x] = [];
      }
      a[x][y] = z;
      b[x] = Math.min.apply(Math, a[x]);
      this.tfNews = Object.assign({}, this.tfNews, a);
      this.tfHeights = Object.assign({}, this.tfHeights, b);
    },
  },
};
export const TextFill = {
  props: ["groupSize", "maxFontSize"],
  watch: {
    groupSize: function (newVal, oldVal) {
      if (newVal) {
        this.gz = newVal;
        const f = Math.min(newVal, this.newFontSize);
        this.$refs.previewText.style["font-size"] = f + "px";
        this.$refs.previewText.style["line-height"] = f + 4 + "px";
      }
    },
  },
  data: function () {
    return {
      renderCount: 0,
    };
  },
  created: function () {
    this.gz = this.groupSize;
    const _self = this;
    this.mq = window.matchMedia(DESKTOP_QUERY);
    this.actualWidth = window.outerWidth;
    window.addEventListener("textfill", () => {
      this.renderCount = 0;
      this.gz = undefined;
      _self.render();
    });
    window.addEventListener(
      "resize",
      debounce(() => {
        if (this.actualWidth != window.outerWidth) {
          if (window.outerWidth > 767) {
            this.actualWidth = window.outerWidth;
            this.renderCount = 0;
            this.gz = undefined;
            _self.render();
          } else {
            _self.resetScale();
          }
        }
      }, 750)
    );
  },
  mounted: function () {
    if (window.outerWidth > 767) {
      this.maxFontSize = this.maxFontSize ? this.maxFontSize : 30;
      this.windowSize = [1, this.maxFontSize];
      this.render();
      setTimeout(() => {
        this.render();
      }, 2000);
    }
  },
  methods: {
    render: function () {
      if (this.gz) {
        this.newFontSize = Math.floor(this.gz / 2);
        this.windowSize = [1, this.gz];
      } else {
        this.newFontSize = Math.floor(this.maxFontSize / 2);
        this.windowSize = [1, this.maxFontSize];
      }
      this.steps = 0;
      this.scaleText();
    },
    scaleText: function () {
      this.steps++;
      this.$refs.previewText.style["font-size"] = this.newFontSize + "px";
      this.$refs.previewText.style["line-height"] = this.newFontSize + 4 + "px";
      if (
        this.$refs.previewText.scrollHeight >
          this.$refs.previewText.offsetHeight ||
        this.$refs.previewText.scrollWidth > this.$refs.previewText.offsetWidth
      ) {
        this.windowSize[1] = this.newFontSize;
        this.newFontSize = Math.floor(
          this.windowSize.reduce(
            (all, one, _, src) => (all += one / src.length),
            0
          )
        );
      } else {
        if (this.windowSize[0] == this.newFontSize) {
          this.$emit("new-font-size", this.newFontSize);
          return;
        } else {
          this.windowSize[0] = this.newFontSize;
          this.newFontSize = Math.ceil(
            this.windowSize.reduce(
              (all, one, _, src) => (all += one / src.length),
              0
            )
          );
        }
      }
      this.scaleText();
    },
    resetScale: function () {
      this.$refs.previewText.style["font-size"] = null;
      this.$refs.previewText.style["line-height"] = null;
      this.$emit("new-font-size", this.maxFontSize);
    },
  },
};

export default TextFill;
</script>