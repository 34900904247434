<template>
  <div class="search-wrap" :class="{ show: isOpen }">
    <button class="close" type="button" @click="close">x</button>
    <div class="container">
      <div
        class="d-flex align-items-center justify-content-center wrap-results-general"
      >
        <div class="col-md-8">
          <form action="/buscar">
            <div class="searh-input-general d-flex nav-down">
              <div class="col auto p-0">
                <input
                  class="search__input"
                  ref="topic"
                  name="term"
                  type="text"
                  placeholder="Buscar artículos"
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                  :value="topic"
                  @input="(evt) => (topic = evt.target.value)"
                  v-on:keydown.up.prevent="inputUp($event)"
                  v-on:keydown.down.prevent="inputDown($event)"
                  v-on:keydown.enter.prevent="inputEnter"
                />
              </div>
              <button ref="dosearch" class="col-auto lupa">
                <i class="icon-search"></i>
              </button>
            </div>
          </form>
          <div v-if="hasResults" class="autocomplete-results">
            <div v-if="acResult.tag && acResult.tag.length">
              <div>
                <div class="name-module">
                  <div class="d-flex flex-column">
                    <div class="dot yellow"></div>
                    <div class="dot blue"></div>
                    <div class="dot red"></div>
                  </div>
                  <h2 class="tittle-module">Temas</h2>
                </div>
                <div class="d-flex flex-wrap">
                  <div
                    v-for="(item, index) in acResult.tag"
                    :key="index"
                    class="item-theme"
                  >
                    <a
                      ref="result"
                      :href="item.url"
                      v-html="item.title"
                      v-bind:class="{ focus: item.idx == focus }"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="acResult.category && acResult.category.length">
              <div>
                <div class="name-module">
                  <div class="d-flex flex-column">
                    <div class="dot yellow"></div>
                    <div class="dot blue"></div>
                    <div class="dot red"></div>
                  </div>
                  <h2 class="tittle-module">Secciones</h2>
                </div>
                <div class="d-flex">
                  <div
                    v-for="(item, index) in acResult.category"
                    :key="index"
                    class="item-theme"
                  >
                    <a
                      ref="result"
                      :href="item.url"
                      v-html="item.title"
                      v-bind:class="{ focus: item.idx == focus }"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="acResult.newspaper && acResult.newspaper.length">
              <div>
                <div class="name-module">
                  <div class="d-flex flex-column">
                    <div class="dot yellow"></div>
                    <div class="dot blue"></div>
                    <div class="dot red"></div>
                  </div>
                  <h2 class="tittle-module">Periodicos</h2>
                </div>
                <div class="d-flex">
                  <div
                    v-for="(item, index) in acResult.newspaper"
                    :key="index"
                    class="item-theme"
                  >
                    <a
                      ref="result"
                      :href="item.url"
                      v-html="item.title"
                      v-bind:class="{ focus: item.idx == focus }"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="acResult.post && acResult.post.length">
              <div>
                <div class="name-module">
                  <div class="d-flex flex-column">
                    <div class="dot yellow"></div>
                    <div class="dot blue"></div>
                    <div class="dot red"></div>
                  </div>
                  <h2 class="tittle-module">Artículos</h2>
                </div>
                <div>
                  <div
                    v-for="(item, index) in acResult.post"
                    :key="index"
                    class="item-article"
                  >
                    <a
                      ref="result"
                      :href="item.url"
                      v-html="item.title"
                      v-bind:class="{ focus: item.idx == focus }"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import axios from "axios";
export default {
  props: {
    isOpen: Boolean,
  },
  mounted: function () {
    document.addEventListener("keyup", this.keyup);
  },
  updated: function () {
    if (this.isOpen) {
      this.$nextTick(() => this.$refs.topic.focus());
    }
  },
  watch: {
    topic: function (newVal, oldVal) {
      this.autocomplete();
    },
  },
  computed: {
    hasResults() {
      return (
        this.acResult &&
        ((this.acResult.post && this.acResult.post.length) ||
          (this.acResult.tag && this.acResult.tag.length) ||
          (this.acResult.category && this.acResult.category.length) ||
          (this.acResult.newspaper && this.acResult.newspaper.length))
      );
    },
  },
  methods: {
    inputUp: function (e) {
      e.target.setSelectionRange;
      if (this.acResult && this.focus != -1) {
        this.focus--;
      }
      if (this.focus > -1) {
        this.topic = this.acAll[this.focus].rawTitle;
      } else {
        this.topic = this.tmpTopic;
      }
    },
    inputDown: function (e) {
      if (this.acResult) {
        this.focus =
          this.focus == this.totalResults - 1 ? this.focus : this.focus + 1;
        this.topic = this.acAll[this.focus].rawTitle;
      }
    },
    inputEnter: function () {
      if (this.focus > -1) {
        const ff = this.$refs.result.filter((q) =>
          q.classList.contains("focus")
        );
        if (ff.length) {
          ff[0].click();
        }
      } else {
        this.$refs.dosearch.click();
      }
    },
    keyup: function (event) {
      if (event.isComposing || event.keyCode === 229) {
        return;
      }
      const _self = this;
      if (this.isOpen) {
        if (event.keyCode == 27) {
          this.close();
        }
      } else {
        if (event.keyCode == 66 && event.altKey) {
          this.open();
        }
      }
    },
    autocomplete: debounce(function () {
      this.searching = true;
      const _self = this;
      this.focus = -1;
      this.totalResults = -1;
      this.acAll = [];
      this.tmpTopic = this.topic;
      if (this.topic) {
        axios.get("/api/ac/" + this.topic).then(function (d) {
          let idx = 0;
          const dat = {
            tag: d.data.tag.map((q) => {
              q.idx = idx++;
              _self.acAll.push(q);
              return q;
            }),
            category: d.data.category.map((q) => {
              q.idx = idx++;
              _self.acAll.push(q);
              return q;
            }),
            newspaper: d.data.newspaper.map((q) => {
              q.idx = idx++;
              _self.acAll.push(q);
              return q;
            }),
            post: d.data.post.map((q) => {
              q.idx = idx++;
              _self.acAll.push(q);
              return q;
            }),
          };
          _self.totalResults = idx;
          _self.acResult = dat;
          _self.searching = false;
        });
      } else {
        this.clean();
      }
    }, 250),
    close: function () {
      this.clean();
      this.$emit("closed");
    },
    open: function () {
      this.$emit("opened");
    },
    clean: function () {
      this.topic = null;
      this.tmpTopic = null;
      this.acResult = null;
      this.acAll = null;
    },
  },
  data: function () {
    return {
      topic: null,
      acResult: null,
      focus: -1,
    };
  },
};
</script>