import {
    initMain
} from './vue/controllers/main';
import {
    header
} from './vue/controllers/header';
import lazyload from './features/lazy-load';
import {
    TextFill,
    tfmixin
} from './vue/components/text-fill/TextFill.vue';

lazyload();
window.homeVue = initMain([tfmixin], {
    TextFill
});
header();