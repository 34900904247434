var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-wrap", class: { show: _vm.isOpen } },
    [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { type: "button" },
          on: { click: _vm.close },
        },
        [_vm._v("x")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-center wrap-results-general",
          },
          [
            _c("div", { staticClass: "col-md-8" }, [
              _c("form", { attrs: { action: "/buscar" } }, [
                _c(
                  "div",
                  { staticClass: "searh-input-general d-flex nav-down" },
                  [
                    _c("div", { staticClass: "col auto p-0" }, [
                      _c("input", {
                        ref: "topic",
                        staticClass: "search__input",
                        attrs: {
                          name: "term",
                          type: "text",
                          placeholder: "Buscar artículos",
                          autocomplete: "off",
                          autocorrect: "off",
                          autocapitalize: "off",
                          spellcheck: "false",
                        },
                        domProps: { value: _vm.topic },
                        on: {
                          input: (evt) => (_vm.topic = evt.target.value),
                          keydown: [
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "up", 38, $event.key, [
                                  "Up",
                                  "ArrowUp",
                                ])
                              )
                                return null
                              $event.preventDefault()
                              return _vm.inputUp($event)
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "down", 40, $event.key, [
                                  "Down",
                                  "ArrowDown",
                                ])
                              )
                                return null
                              $event.preventDefault()
                              return _vm.inputDown($event)
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              $event.preventDefault()
                              return _vm.inputEnter.apply(null, arguments)
                            },
                          ],
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      { ref: "dosearch", staticClass: "col-auto lupa" },
                      [_c("i", { staticClass: "icon-search" })]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.hasResults
                ? _c("div", { staticClass: "autocomplete-results" }, [
                    _vm.acResult.tag && _vm.acResult.tag.length
                      ? _c("div", [
                          _c("div", [
                            _vm._m(0),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-wrap" },
                              _vm._l(_vm.acResult.tag, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "item-theme" },
                                  [
                                    _c("a", {
                                      ref: "result",
                                      refInFor: true,
                                      class: { focus: item.idx == _vm.focus },
                                      attrs: { href: item.url },
                                      domProps: {
                                        innerHTML: _vm._s(item.title),
                                      },
                                    }),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.acResult.category && _vm.acResult.category.length
                      ? _c("div", [
                          _c("div", [
                            _vm._m(1),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              _vm._l(
                                _vm.acResult.category,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "item-theme" },
                                    [
                                      _c("a", {
                                        ref: "result",
                                        refInFor: true,
                                        class: { focus: item.idx == _vm.focus },
                                        attrs: { href: item.url },
                                        domProps: {
                                          innerHTML: _vm._s(item.title),
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.acResult.newspaper && _vm.acResult.newspaper.length
                      ? _c("div", [
                          _c("div", [
                            _vm._m(2),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              _vm._l(
                                _vm.acResult.newspaper,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "item-theme" },
                                    [
                                      _c("a", {
                                        ref: "result",
                                        refInFor: true,
                                        class: { focus: item.idx == _vm.focus },
                                        attrs: { href: item.url },
                                        domProps: {
                                          innerHTML: _vm._s(item.title),
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.acResult.post && _vm.acResult.post.length
                      ? _c("div", [
                          _c("div", [
                            _vm._m(3),
                            _vm._v(" "),
                            _c(
                              "div",
                              _vm._l(_vm.acResult.post, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "item-article" },
                                  [
                                    _c("a", {
                                      ref: "result",
                                      refInFor: true,
                                      class: { focus: item.idx == _vm.focus },
                                      attrs: { href: item.url },
                                      domProps: {
                                        innerHTML: _vm._s(item.title),
                                      },
                                    }),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name-module" }, [
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c("div", { staticClass: "dot yellow" }),
        _vm._v(" "),
        _c("div", { staticClass: "dot blue" }),
        _vm._v(" "),
        _c("div", { staticClass: "dot red" }),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "tittle-module" }, [_vm._v("Temas")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name-module" }, [
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c("div", { staticClass: "dot yellow" }),
        _vm._v(" "),
        _c("div", { staticClass: "dot blue" }),
        _vm._v(" "),
        _c("div", { staticClass: "dot red" }),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "tittle-module" }, [_vm._v("Secciones")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name-module" }, [
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c("div", { staticClass: "dot yellow" }),
        _vm._v(" "),
        _c("div", { staticClass: "dot blue" }),
        _vm._v(" "),
        _c("div", { staticClass: "dot red" }),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "tittle-module" }, [_vm._v("Periodicos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name-module" }, [
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c("div", { staticClass: "dot yellow" }),
        _vm._v(" "),
        _c("div", { staticClass: "dot blue" }),
        _vm._v(" "),
        _c("div", { staticClass: "dot red" }),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "tittle-module" }, [_vm._v("Artículos")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }