import Vue from 'vue';
import SearchBox from '../components/search-box/SearchBox.vue';
export const header = () => {
    const mainSelector = '#vue-header';
    return new Vue({
        el: mainSelector,
        components: {
            SearchBox
        },
        data: function () {
            return {
                searchBoxIsOpen: false,
                menuMobileIsOpen: false,
                isHeaderFixed: false,
                headerScrollRef: 165
            };
        },
        created: function () {
            this.handleScroll(window.scrollY);
            let last_known_scroll_position = 0;
            let ticking = false;
            const _self = this;
            window.addEventListener('scroll', function (e) {
                last_known_scroll_position = window.scrollY;
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        _self.handleScroll(last_known_scroll_position);
                        ticking = false;
                    });
                }
                ticking = true;
            });
        },
        watch: {
            searchBoxIsOpen: function (newVal, oldVal) {
                if (newVal) {
                    document.body.classList.add("overflow-hidden");
                } else {
                    document.body.classList.remove("overflow-hidden");
                }
            }
        },
        methods: {
            toggleMenuMobile() {
                this.menuMobileIsOpen = !this.menuMobileIsOpen;
                if (this.menuMobileIsOpen) {
                    document.body.classList.add("overflow-hidden");
                } else {
                    document.body.classList.remove("overflow-hidden");
                }
            },
            handleScroll(scrollY) {
                this.isHeaderFixed = scrollY > this.headerScrollRef;
            },
        }
    });
};