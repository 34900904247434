"use strict";

export const execute = () => {
    let lazyObjects = [].slice.call(
        document.querySelectorAll(".lazyload[data-src]")
    );
    if ("IntersectionObserver" in window) {
        const lazyObjectObserver = new IntersectionObserver(
            function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        const lazyObject = entry.target;
                        if (lazyObject.classList.contains("lazyload")) {
                            if (lazyObject.tagName == "IFRAME") {
                                const srcIframe = lazyObject.dataset.src;
                                lazyObject.contentWindow.location.replace(lazyObject.dataset.src);

                                if (srcIframe.indexOf('widgets') > -1) {
                                    iFrameResize({
                                        checkOrigin: false
                                    }, 'iframe[data-src*="widgets"]');
                                }
                            } else {
                                lazyObject.src = lazyObject.dataset.src;
                            }
                            lazyObject.removeAttribute('data-src');
                            if (lazyObject.dataset.srcset) {
                                lazyObject.srcset = lazyObject.dataset.srcset;
                                lazyObject.removeAttribute('data-srcset');
                            }

                            lazyObject.classList.remove("lazyload");
                            lazyObjectObserver.unobserve(lazyObject);
                        }
                    }
                });
            }, {
                rootMargin: "0px 0px 256px 0px"
            }
        );
        lazyObjects.forEach(function (lazyImage) {
            lazyObjectObserver.observe(lazyImage);
        });
    } else {
        let active = false;
        const lazyLoad = function () {
            if (active === false) {
                active = true;

                setTimeout(function () {
                    lazyObjects.forEach(function (lazyImage) {
                        if (
                            lazyImage.getBoundingClientRect().top <= window.innerHeight &&
                            lazyImage.getBoundingClientRect().bottom >= 0 &&
                            getComputedStyle(lazyImage).display !== "none" &&
                            lazyImage.classList.contains("lazyload")
                        ) {
                            lazyImage.src = lazyImage.dataset.src;
                            if (lazyImage.dataset.srcset) {
                                lazyImage.srcset = lazyImage.dataset.srcset
                            }
                            lazyImage.classList.remove("lazyload");

                            lazyObjects = lazyObjects.filter(function (image) {
                                return image !== lazyImage;
                            });

                            if (lazyObjects.length === 0) {
                                document.removeEventListener("scroll", lazyLoad);
                                window.removeEventListener("resize", lazyLoad);
                                window.removeEventListener("orientationchange", lazyLoad);
                            }
                        }
                    });

                    active = false;
                }, 200);
            }
        };

        document.addEventListener("scroll", lazyLoad);
        window.addEventListener("resize", lazyLoad);
        window.addEventListener("orientationchange", lazyLoad);
        lazyLoad();
    }
}

export default () => {
    window.addEventListener("load", function () {
        execute();
    });
};